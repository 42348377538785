<template>
	<div>
		<page-service :is-shop="1"></page-service>
	</div>
</template>

<script>
	import PageService from '@/components/layout/land/page-service.vue'
	export default{
		components:{
			PageService
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style>
</style>
