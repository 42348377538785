<template>
	<!-- 服务管理 -->
	<div class="el-content">
		<router-link :to="{path:isShop == 0 ? '/land/service_edit' :'/many_store/land/service_edit'}">
			<a-button 
				type="primary"
				class="mb12" 
				v-has="{action:'/land/service_edit',plat:isShop}">
				<i class="ri-add-line"></i>添加服务
			</a-button>
		</router-link>
		<a-table :data-source="info.list" row-key="id" :pagination="false" :columns="[
			{dataIndex:'id',title:'ID'},
			{dataIndex:'name',title:'服务名称'},
			{dataIndex:'price',title:'服务价格(天)'},
			{dataIndex:'ser_type',title:'服务类 型',slots:{customRender:'ser_type'}},
			{dataIndex:'action',title:'操作',slots:{customRender:'action'}},
		]" >
		    <template #ser_type="{record}">
				<a-tag color="#2db7f5" v-if="record.ser_type==1">包种植+包配送</a-tag>
				<a-tag color="#2db7f5" v-if="record.ser_type==2">包种植</a-tag>
				<a-tag color="#2db7f5" v-if="record.ser_type==3">仅出租土地，不含其它服务</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:isShop == 0 ? '/land/service_edit' :'/many_store/land/service_edit',query:{id:record.id}}">
						<kd-button title="编辑"
							icon="ri-edit-line" 
							v-has="{action:'/land/service_edit',plat:isShop}">
						</kd-button>
					</router-link>
					<kd-button title="删除" 
						type="danger" 
						icon="ri-delete-bin-5-line" 
						v-has="{action:'wxapp_land_ser_del',plat:isShop}" 
						@click="delService([record.id])">
					</kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
			    show-size-changer
			    :default-current="info.page"
			    :total="info.count"
			    @showSizeChange="(p,e)=>{getServiceList(info.page,e)}"
				@change="(e)=>{getServiceList(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import landModel from '@/api/land.js'
import { reactive, toRefs } from 'vue'
export default{
	name:'page-service',
	props:{
		isShop:{
			type:Number
		}
	},
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			}
		})
		getServiceList(1,_d.info.limit)
		function getServiceList(page,limit){
			landModel.getLandServer(page,limit,null,res=>{
				_d.info = {limit,...res}
			})
		}
		const delService = (id)=>landModel.deleteLandServer(id,()=>getServiceList(_d.info.page,_d.info.limit))
		return{
			...toRefs(_d),
			delService,
			getServiceList
		}
	}
}
</script>
<style>
</style>
